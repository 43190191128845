import React from "react";
import Signup from "./Signup";
import Login from "./Login";
import { Route, Routes } from "react-router-dom";
import Footer from "../../components/Footer";

const Auth = () => {
  return (
    <div className="w-full flex flex-col items-center relative">
      <div
        className="fixed w-full h-full blur-3xl bg-no-repeat bg-cover md:bg-auto bg-center bg-white"
        style={{ backgroundImage: `url(https://traddocs.s3.ap-northeast-2.amazonaws.com/landing/background.png)` }}
      />
      <div
        style={{ minHeight: "calc(100vh - 10rem)" }}
        className="w-full flex flex-col items-center justify-center relative  flex-shrink-0 py-36"
      >
        <div className="z-20">
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
          </Routes>
        </div>
      </div>
      <div className="z-20 w-full">
        <Footer />
      </div>
    </div>
  );
};

export default Auth;
