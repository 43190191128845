import React from "react";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Partners = () => {
  const { t } = useTranslation("landing");
  var settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  return (
    <div className="w-full py-20 flex justify-center text-gray-700">
      <div className="max-w-screen-xl w-full h-full flex flex-col items-center justify-center px-4 md:px-16">
        <div className="text-gray-500 font-light text-lg text-center">
          <p className="inline">{t("partners.title1")}</p>
          <p className="inline font-semibold text-black"> {t("partners.title2")} </p>
          <p className="inline">{t("partners.title3")}</p>
        </div>

        <div style={{ filter: "grayscale(100%)", opacity: "0.8" }} className="mt-12 w-full">
          <Slider {...settings} autoplay infinite className="w-full flex items-center">
            {[
              { src: "partner-1.png", alt: "Partner 1" },
              { src: "ulsan.svg", alt: "Partner 3" },
              { src: "partner-2.png", alt: "Partner 2" },
              { src: "partner-4.png", alt: "Partner 4" },
              { src: "partner-7.svg", alt: "Partner 7" },
            ].map((partner) => (
              <div key={partner.alt}>
                <div className="h-[6rem] flex items-center justify-center">
                  <img
                    src={`https://traddocs.s3.ap-northeast-2.amazonaws.com/landing/partners/${partner.src}`}
                    draggable={false}
                    className={`max-w-[9rem] max-h-[4rem] mx-auto`}
                    style={{ objectFit: "contain" }} // Ensure full height usage within container
                    alt={partner.alt}
                  />
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Partners;
