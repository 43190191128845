import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { VscThreeBars } from "react-icons/vsc";
import { Dropdown } from "antd";
import { AuthContext } from "../context/authContext";
import ProfileButton from "./ProfileButton";

const NavBar = () => {
  const { t } = useTranslation("landing");
  const { userState } = useContext(AuthContext);

  const items = [
    {
      key: "1",
      label: (
        <Link to="/company">
          <button className="text-sm hover:text-blue-500 transition flex-shrink-0 h-14 w-48">{t("nav.company")}</button>
        </Link>
      ),
    },
    {
      key: "2",
      label: (
        <Link to="/pricing">
          <button className="text-sm hover:text-blue-500 transition h-14 w-48">{t("nav.pricing")}</button>
        </Link>
      ),
    },
    {
      key: "3",
      label: (
        <Link to="/contact-us">
          <button className="text-sm hover:text-blue-500 transition h-14 w-48">{t("nav.contact")}</button>
        </Link>
      ),
    },
  ];

  const NavButton = ({ path, title }) => {
    if (path)
      return (
        <Link
          to={path}
          className="hidden md:flex hover:bg-gray-100 px-3 py-1.5 rounded-full group font-WantedSans transition font-medium"
        >
          <button className="group-hover:text-red-700 text-gray-600 transition">{title}</button>
        </Link>
      );
    else return <button className="group-hover:text-red-700 text-gray-600 transition">{title}</button>;
  };

  return (
    <header className="w-full flex-shrink-0 flex flex-col items-center justify-center z-30 fixed top-0  bg-white">
      <div className="flex items-center font-medium w-full max-w-screen-xl justify-between rounded-full px-5 lg:px-10 py-4 lg:py-0 h-20">
        <Link to="/">
          <div className="flex items-center">
            <img
              src={"https://traddocs.s3.ap-northeast-2.amazonaws.com/landing/logo_name_red.png"}
              alt=""
              className="h-4 md:h-4 object-contain"
              draggable={false}
            />
          </div>
        </Link>
        <div className="flex flex-shrink-0 space-x-16 font-medium items-center text-gray-700">
          <div className="flex items-center space-x-10 font-medium">
            <NavButton path={"/company"} title={t("nav.company")} />
            <NavButton path={"/pricing"} title={t("nav.pricing")} />
            <NavButton path={"/contact-us"} title={t("nav.contact")} />
          </div>
        </div>

        <div>
          {userState.isAuthenticated ? (
            <ProfileButton />
          ) : (
            <>
              <div className="hidden md:flex space-x-2 items-center justify-end font-medium">
                <Link className="flex-shrink-0" to={t("/auth/login")}>
                  <button className="h-9 px-4 md:h-9 md:px-5 transition flex-shrink-0 hover:opacity-80 text-gray-700">
                    {t("login")}
                  </button>
                </Link>
                <Link className="flex-shrink-0" to={t("/auth/signup")}>
                  <button className="bg-blue-500 h-9 px-3.5 rounded-full text-white transition flex-shrink-0 hover:opacity-80">
                    {t("getStarted")}
                  </button>
                </Link>
              </div>
              <div className="md:hidden flex">
                <Dropdown menu={{ items }} placement="bottomRight">
                  <button className="group md:hidden flex md:w-0 w-14 h-14 text-sm items-center justify-center">
                    <VscThreeBars className="w-6 h-6 text-gray-700" />
                  </button>
                </Dropdown>
              </div>
            </>
          )}
        </div>
      </div>
    </header>
  );
};

export default NavBar;
