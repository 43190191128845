import React from "react";
import { useTranslation } from "react-i18next";
import Welcome from "../components/Landing/Welcome";
import Footer from "../components/Footer";
import Partners from "../components/Landing/Partners";
import { FaLock } from "react-icons/fa";
import SolutionIntro from "../components/Landing/SolutionIntro";
import DocumentTypesIntro from "../components/Landing/DocumentTypesIntro";
import CheckScopeIntro from "../components/Landing/CheckScopeIntro";
import { Link } from "react-router-dom";

const Landing = () => {
  const { t } = useTranslation("landing");

  const SolutionCard = ({ icon, title, subtitle }) => (
    <div className="w-full p-8 md:p-10 flex flex-col rounded-3xl">
      <div className="text-lg md:text-xl font-medium break-keep leading-tight">
        <p>{title}</p>
      </div>
      <p className="mt-8 break-keep text-sm text-gray-500 max-w-md">{subtitle}</p>
    </div>
  );

  const ProblemSection = () => (
    <div className="flex w-full items-center justify-center flex-col md:py-24 py-16 text-gray-700 px-4">
      <div className="font-medium w-full justify-center flex text-blue-600 mb-4">Requested Feature by Customer</div>
      <p style={{ whiteSpace: "pre-wrap" }} className="text-3xl mb-6 font-semibold text-center leading-normal">
        {`"Can you enhance the Search Process for\nIMPFOOD Imports to South Korea?"`}
      </p>
      <p className="mt-4 mb-8 break-keep text-base max-w-lg text-gray-500 leading-relaxed text-center w-full">
        We build features for our customers. Share your feature requests with our team. We're dedicated to delivering
        the solutions you need.
      </p>
      <Link to="/contact-us">
        <button className="bg-blue-500 h-9 px-3.5 rounded-full shadow text-white transition flex-shrink-0 hover:opacity-80 mb-16">
          Contact our team
        </button>
      </Link>

      <div className="max-w-screen-lg md:px-16 w-full grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-8">
        <SolutionCard
          title={"Mismatched Shipper Names"}
          subtitle={
            "We developed a feature to solve discrepancies where documents say TradDocs LLC but Impfood lists it as TradDocs Limited Liability Company. Our service can find these mismatches too."
          }
        />
        <SolutionCard
          title={"Unusual Symbols in Names"}
          subtitle={
            "We created a feature to handle unusual symbols in shipper names from Europe or South America (æ, ü). No need to alter the shipper's name manually—just upload the document, and it's automatically found."
          }
        />
        <SolutionCard
          title={"Changed Shipper Names"}
          subtitle={
            "We added a feature to detect when a shipper's name has changed. If you search for a familiar shipper and don't find updated information, our service automatically identifies these changes."
          }
        />
        <SolutionCard
          title={"New or Unregistered Shippers"}
          subtitle={
            "We implemented a feature to avoid wasting time on searches at Impfood that may be problematic or involve unregistered shippers—use 'TradDocs' for accurate results."
          }
        />
      </div>
    </div>
  );

  const SecuritySection = () => (
    <div className="my-16 py-16 max-w-screen-xl w-full h-full flex flex-col items-center px-4 md:px-16 rounded-xl bg-gradient-to-b from-white to-sky-50">
      <div className="font-medium w-full justify-center flex text-blue-600 mb-6 space-x-3 text-lg items-center">
        <FaLock className="w-4 h-4" />
        <p>{t("security")}</p>
      </div>
      <div style={{ whiteSpace: "pre-wrap" }} className="text-4xl mb-4 font-semibold text-center leading-normal">
        <p className="flex w-full justify-center break-keep text-center">{"Secure Customer Data\nWith TradDocs"}</p>
      </div>
      <p className="mt-4 mb-6 break-keep text-lg max-w-lg text-gray-500 leading-relaxed text-center w-full">
        {t("securitySubtitle")}
      </p>

      <button className="text-blue-600 hover:underline my-8 text-base">{t("learnMore")} →</button>
    </div>
  );

  return (
    <div className="w-full flex items-center flex-col relative pt-20">
      <div
        className="fixed w-full h-full blur-3xl bg-no-repeat bg-cover md:bg-auto bg-center"
        style={{ backgroundImage: `url(https://traddocs.s3.ap-northeast-2.amazonaws.com/landing/background.png)` }}
      />
      <div className="w-full z-20 flex flex-col items-center">
        <Welcome />
        <Partners />
        <SolutionIntro />
        <CheckScopeIntro />
        <DocumentTypesIntro />
        <ProblemSection />
        <SecuritySection />
        <Footer />
      </div>
    </div>
  );
};

export default Landing;
