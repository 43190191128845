import "./App.css";
import { useContext } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { AuthContext } from "./context/authContext";
import SEOMetaTag from "./utils/SEOMetaTag";
import ScrollToTop from "./utils/scrollToTop";
import { AxiosInterceptor } from "./utils/authAxios";
import Landing from "./pages/Landing";
import Company from "./pages/Company";
import Contact from "./pages/Contact";
import Auth from "./pages/Auth/Auth";
import Pricing from "./pages/Pricing";
import Legal from "./pages/Legal/Legal";
import NavBar from "./components/NavBar";
import Main from "./pages/Main/Main";
import { pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = require("pdfjs-dist/build/pdf.worker.entry.js");

function App() {
  const { userState } = useContext(AuthContext);

  if (userState.isAuthenticated)
    return (
      <AxiosInterceptor>
        <div style={{ height: "100svh" }} className="flex flex-col items-center w-full overflow-x-hidden">
          <ScrollToTop />
          <Routes>
            <Route path="/*" element={<Main />} />
          </Routes>
        </div>
      </AxiosInterceptor>
    );
  else
    return (
      <AxiosInterceptor>
        <NavBar />
        <div className="flex flex-col items-center w-full overflow-x-hidden text-gray-800">
          <ScrollToTop />
          <SEOMetaTag
            title="TradDocs - AI-Powered Trade Document Automation | Trade Finance Solutions"
            description="TradDocs is the leading platform for automating international trade documents, including Letters of Credit and Bills of Lading, enhancing accuracy and efficiency in global trade."
            keywords="trade document automation, AI trade finance, Letter of Credit automation, Bills of Lading automation, trade finance solutions, secure document processing, international trade AI, efficient trade documentation, trade finance platform, document discrepancy inspection"
            url="https://www.traddocs.com"
            imgsrc="https://traddocs.s3.ap-northeast-2.amazonaws.com/new_traddocs_og.png"
          />
          <Routes>
            <Route path="/*" element={<Landing />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/company" element={<Company />} />
            <Route path="/contact-us" element={<Contact />} />
            <Route path="/legal/*" element={<Legal />} />
            <Route path="/auth/*" element={userState.isAuthenticated ? <Navigate to="/" /> : <Auth />} />
          </Routes>
        </div>
      </AxiosInterceptor>
    );
}

export default App;
