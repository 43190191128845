import React from "react";
import Footer from "../components/Footer";
import { useTranslation } from "react-i18next";
import SEOMetaTag from "../utils/SEOMetaTag";
import { Link } from "react-router-dom";
import { FaLinkedin } from "react-icons/fa";
import Partners from "../components/Landing/Partners";

const Company = () => {
  const { t } = useTranslation("company");

  const WelcomeSection = () => (
    <div className="w-full flex justify-center relative pt-32">
      <div className="max-w-screen-lg w-full h-full flex items-center px-4 md:px-16 z-20 text-center py-20">
        <div className="w-full flex flex-col justify-center items-center text-gray-700 break-keep">
          <h1
            style={{ whiteSpace: "pre-wrap" }}
            className="font-semibold text-5xl md:text-6xl leading-tight md:leading-snug tracking-[-0.01em]"
          >{`We transform the operations\nof international trade`}</h1>

          <h2 className="mt-8 md:mt-10 break-keep text-base md:text-xl max-w-xl text-gray-500 leading-relaxed">
            {t("subtitle")}
          </h2>
        </div>
      </div>
    </div>
  );

  const ImageSection = () => (
    <div className="w-full flex justify-center relative">
      <div className="max-w-screen-xl w-full h-full px-4">
        <div className="space-y-4">
          <div className="flex w-full justify-center items-center space-x-4 h-80">
            <img
              src={"https://traddocs.s3.ap-northeast-2.amazonaws.com/landing/company1.png"}
              alt=""
              className="w-2/3 h-full object-cover"
            />
            <img
              src={"https://traddocs.s3.ap-northeast-2.amazonaws.com/landing/company4.jpg"}
              alt=""
              className="w-1/3 h-full object-cover"
            />
          </div>
          <div className="flex w-full justify-center items-center space-x-4 h-80">
            <img
              src={"https://traddocs.s3.ap-northeast-2.amazonaws.com/landing/company2.png"}
              alt=""
              className="w-1/3 h-full object-cover"
            />
            <img
              src={"https://traddocs.s3.ap-northeast-2.amazonaws.com/landing/company3.png"}
              alt=""
              className="w-2/3 h-full object-cover"
            />
          </div>
        </div>
      </div>
    </div>
  );

  const AboutSection = () => (
    <div className="w-full py-8 md:py-16 flex justify-center text-gray-700 h-full">
      <div className="max-w-screen-lg w-full h-full flex flex-col items-center md:items-start md:px-0 px-4">
        <div className="p-4 py-8 md:px-12 w-full break-keep tracking-tight">
          <h2 className="text-4xl font-semibold mb-12">{t("header")}</h2>
          <p className="break-keep text-xl leading-relaxed">{t("body1")}</p>
          <p className="break-keep text-xl leading-relaxed mt-10">{t("body2")}</p>
          <p className="break-keep text-xl leading-relaxed mt-10">{t("body3")}</p>
          <p className="break-keep text-xl leading-relaxed mt-10">{t("body4")}</p>
          <p className="break-keep text-xl leading-relaxed mt-10">{t("body5")}</p>
          <p className="break-keep text-xl leading-relaxed mt-10">{t("body6")}</p>

          <div className="w-full mt-10">
            <div className="flex space-x-2">
              <p className="font-semibold text-xl">{t("leadership.cto")}</p>
              <div className="flex items-center justify-between">
                <Link to="https://www.linkedin.com/in/andrew-jang-0/">
                  <FaLinkedin style={{ color: "#0072b1" }} className="w-6 h-6 text-white" />
                </Link>
              </div>
            </div>
            <p className="mt-1 text-xl">TradDocs COO</p>
            <p className="mt-1 text-xl">Seoul, South Korea</p>

            <div className="w-16 h-20 overflow-hidden rounded-lg flex items-start justify-center mt-4">
              <img
                style={{ aspectRatio: 1 }}
                src={"https://traddocs.s3.ap-northeast-2.amazonaws.com/landing/team2.png"}
                className="w-32 h-32 object-cover object-top -mt-3"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const PartnerSection = () => (
    <div className="w-full py-20 pb-12 bg-gray-100 flex justify-center text-gray-700">
      <div className="max-w-screen-xl w-full h-full flex flex-col items-center justify-center px-4 md:px-16">
        <div className="w-full flex flex-col justify-center items-center text-gray-700 break-keep">
          <h1 className="font-semibold text-3xl md:text-4xl leading-tight">{t("partnerTitle1")}</h1>
          <h1 className="font-semibold text-3xl md:text-4xl mt-1 md:mt-4 leading-tight">{t("partnerTitle2")}</h1>
        </div>
        <div className="flex md:flex-row flex-col space-y-8 md:space-y-0 md:space-x-12 items-center mt-20">
          <img
            src={"https://traddocs.s3.ap-northeast-2.amazonaws.com/landing/ms_startup.png"}
            draggable={false}
            className="h-20 md:-mt-4"
            alt=""
          />
          <img
            src={"https://traddocs.s3.ap-northeast-2.amazonaws.com/landing/google_startup.png"}
            draggable={false}
            className="h-8"
            alt=""
          />
        </div>
      </div>
    </div>
  );

  const Vision = () => (
    <div className=" bg-gradient-to-b from-gray-100 to-white w-full py-32 flex justify-center text-gray-700">
      <div className="max-w-screen-xl w-full h-full flex flex-col items-center justify-center px-4 md:px-16">
        <div className="w-full flex flex-col justify-center items-center text-gray-700 break-keep">
          <h1 className="font-semibold text-3xl md:text-4xl leading-tight">{t("vision")}</h1>
          <p className="break-keep mt-12 text-center max-w-2xl text-xl leading-relaxed">{t("visionBody1")}</p>
        </div>
        <div className="grid grid-cols-2 text-lg gap-12 mt-20 text-gray-600">
          <div className="flex items-center space-x-4">
            <div className="w-2 h-2 rounded-full bg-blue-500"></div>
            <p>{t("passionAndCreativity")}</p>
          </div>
          <div className="flex items-center space-x-4">
            <div className="w-2 h-2 rounded-full bg-blue-500"></div>
            <p>{t("collaborationSkills")}</p>
          </div>
          <div className="flex items-center space-x-4">
            <div className="w-2 h-2 rounded-full bg-blue-500"></div>
            <p>{t("willingnessToLearn")}</p>
          </div>
          <div className="flex items-center space-x-4">
            <div className="w-2 h-2 rounded-full bg-blue-500"></div>
            <p>{t("problemSolvingSkills")}</p>
          </div>
          <div className="flex items-center space-x-4">
            <div className="w-2 h-2 rounded-full bg-blue-500"></div>
            <p>{t("adaptabilityAndFlexibility")}</p>
          </div>
          <div className="flex items-center space-x-4">
            <div className="w-2 h-2 rounded-full bg-blue-500"></div>
            <p>{t("globalMindset")}</p>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <SEOMetaTag
        title={t("title")}
        description={t("description")}
        keywords={t("keywords")}
        url="https://www.traddocs.com/company"
      />
      <WelcomeSection />
      <ImageSection />
      <AboutSection />
      <PartnerSection />
      <Vision />
      <Partners />
      <Footer />
    </>
  );
};

export default Company;
