import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../context/authContext";
import { useNavigate } from "react-router-dom";
import axios from "../utils/authAxios";
import { Dropdown } from "antd";
import { HttpStatusCode } from "axios";
import ChangePasswordModal from "./modals/ChangePasswordModal";

const ProfileButton = ({ mode = "light" }) => {
  const { t } = useTranslation("dashboard");
  const { userState, updateAccessToken } = useContext(AuthContext);
  const [changePasswordModalOpen, setChangePasswordModalOpen] = useState(false);
  const navigate = useNavigate();
  const userInfo = userState.user;

  function logout() {
    axios
      .post(`/v1/auth/logout`)
      .then((response) => {
        if (response.status === HttpStatusCode.Ok) {
          updateAccessToken(null).then();
          navigate("/");
        }
      })
      .catch((error) => {
        console.error("Logout error:", error);
      });
  }

  const items = [
    {
      label: <p className="py-2 text-gray-400 text-sm">{userState.user.userEmail}</p>,
      key: "0",
      disabled: true,
    },
    {
      label: <p className="py-2 text-gray-500 text-sm">Change Password</p>,
      key: "1",
      onClick: () => setChangePasswordModalOpen(true),
    },
    { type: "divider" },
    {
      label: <p className="py-2 text-red-500 text-sm">{t("popup.logout")}</p>,
      key: "2",
      onClick: () => logout(),
    },
  ];

  return (
    <div className="flex-shrink-0">
      <ChangePasswordModal open={changePasswordModalOpen} setOpen={setChangePasswordModalOpen} />
      <Dropdown
        menu={{ items }}
        trigger={["click"]}
        placement="bottomRight"
        className="w-full h-full flex items-center cursor-pointer"
      >
        <button
          className={`${
            mode === "dark" ? "hover:bg-gray-600" : "hover:bg-gray-100"
          } h-10 relative flex items-center space-x-2 px-3 py-2 transition`}
        >
          <img
            src={userInfo?.userImg || "https://traddocs.s3.ap-northeast-2.amazonaws.com/landing/default-profile.png"}
            className="w-7 h-7 rounded-full bg-gray-100 flex-shrink-0 overflow-hidden relative flex items-center justify-center object-cover border border-gray-400"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = "https://traddocs.s3.ap-northeast-2.amazonaws.com/landing/default-profile.png";
            }}
            alt="Profile"
          />
          <p className="text-white text-xs">{userInfo.userName}</p>
        </button>
      </Dropdown>
    </div>
  );
};

export default ProfileButton;
