const company = {
  mode: "ko",
  title1: "트레독스는 국제 무역의",
  title2: "오퍼레이션을 변화시킵니다",
  subtitle: "TradDocs는 AI를 통해 국제 무역 운영을 혁신하는 선도적인 플랫폼과 커뮤니티를 창출합니다.",
  header: "우리의 이야기",
  body1:
    "2023년 겨울, 우리는 국제 무역의 복잡한 미로 속에서 빛을 발견했습니다. 저와 제 가장 친한 동료이자 현 트레독스의 CEO는 무역의 어려움을 더는 방관할 수 없다고 결심했습니다. 이것이 TradDocs의 여정이 시작된 순간이었습니다. 우리의 목표는 단순했습니다: 선적서류 작업을 혁신하여 현직에 고생하는 분들의 짐을 덜어주고 모든 이들이 더 쉽게 글로벌 무역에 참여할 수 있도록 만드는 것.",
  body2:
    "제 개인적인 경험을 통해, 문서 처리의 어려움이 얼마나 큰 짐이 될 수 있는지 직접 목격했습니다. 수많은 밤을 문서 검토와 정정 작업에 할애하며, 실수 한 번으로 큰 손실을 경험했습니다. 하지만 이러한 어려움은 우리에게 더 큰 열정을 불어넣었고, 더 나은 방법이 있어야 한다는 확신을 주었습니다.",
  body3:
    "TradDocs를 창립하면서, 우리는 무역의 뒷받침을 담당하는 사무직 근로자들, 수입업자 및 수출업자들의 삶을 개선하는 것을 목표로 삼았습니다. 이들은 무역의 진정한 영웅들이지만, 종종 번거로운 문서 작업으로 인해 그 가치를 충분히 발휘하지 못합니다. 우리의 비전은 이들이 자신의 잠재력을 최대한 발휘하고, 무역 과정을 더 쉽고 빠르게 만들 수 있는 세상을 만드는 것입니다.",
  body4:
    "TradDocs는 혁신적인 AI 기술을 사용하여 선적서류를 자동으로 검토하고 처리함으로써, 선적서류 작업의 속도와 정확성을 획기적으로 개선했습니다. 우리의 플랫폼 덕분에, 무역에 참여하는 모든 이들은 이제 더 신속하게 거래를 완료할 수 있으며, 오류로 인한 스트레스에서 벗어날 수 있습니다.",
  body5:
    "2023년에 시작된 이 여정은 단순히 비즈니스를 넘어선 것입니다. 이는 우리의 열정, 우리의 꿈, 그리고 무엇보다 국제 무역을 더 나은 곳으로 만들고자 하는 우리의 약속입니다. TradDocs와 함께, 우리는 선적서류의 복잡함을 단순화하고, 모든 이가 글로벌 마켓에 쉽게 접근할 수 있는 미래를 향해 나아가고 있습니다.",
  body6:
    "우리의 여정은 인재들의 참여와 고객들의 지원 없이는 완성될 수 없습니다. 함께 우리는 국제 무역의 새로운 장을 열고, 모든 이들이 더 큰 기회를 포용할 수 있는 세상을 만들어갈 수 있습니다. TradDocs와 함께 무역의 미래를 만들어 나가십시오.",
  vision: "우리의 목표는 무역업의 혁신입니다",
  visionBody1:
    "국제 무역의 모든 관련자들이 더욱 효율적이고 정확하게 거래할 수 있는 환경을 조성하는 것을 목표로 합니다. 우리는 이것을 중요시합니다:",
  passionAndCreativity: "열정과 창의력",
  collaborationSkills: "협업 능력",
  willingnessToLearn: "학습 의지",
  problemSolvingSkills: "문제 해결 능력",
  adaptabilityAndFlexibility: "적응성과 유연성",
  globalMindset: "글로벌 마인드셋",

  leadership: {
    subtitle:
      "트레독스에서는 기술 및 무역 금융 분야의 다양한 전문가 팀이 글로벌 무역을 변혁하기 위해 끊임없이 노력합니다. 우리의 독특한 기술과 통찰은 무역 금융을 더 효율적이고 접근하기 쉽게 만드는 우리의 사명을 추진합니다.",
    ceo: "최형우",
    cto: "장동해 Andrew Jang",
  },
  //PARTNER SECTION
  partnerTitle1: "TradDocs는 국제 기업의",
  partnerTitle2: "지원을 통해 나아갑니다",
  //SEO
  title: "회사 소개 | 트레독스 TradDocs",
  description:
    "AI 기술로 국제 선적서류를 자동화하는 선두주자, 트레독스입니다. 국제 무역을 더 안전하고 효율적으로 만듭니다.",
  keywords: "무역 금융 자동화, 트레독스 소개, AI 문서 처리, 국제 무역, 안전한 선적서류화, 무역에서의 AI 혁신",
};

export default company;
