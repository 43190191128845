import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Tooltip } from "antd";
import SubscriptionModal from "../../components/modals/SubscriptionModal";
import { MdDashboard } from "react-icons/md";
import { IoPeopleSharp } from "react-icons/io5";
import { APP_VERSION } from "../../version";
import ProfileButton from "../../components/ProfileButton";
import { BsFileEarmarkSpreadsheetFill } from "react-icons/bs";

const LeftPanel = () => {
  const [open, setOpen] = useState(false);

  // const ClientHelp = () => (
  //   <div className="w-full px-2">
  //     <div className="w-full flex-shrink-0 rounded-lg flex flex-col p-2 items-center bg-white bg-opacity-90 text-black">
  //       <div className="px-3 w-full space-y-3">
  //         <div className=" inline-block text-center break-keep leading-5">
  //           <span className="text-xs inline font-semibold">Unlimited </span>
  //           <span className="text-xs inline opacity-70">
  //             checks left for your {userState.hasSubscription ? "master plan" : "free trial"}.
  //           </span>
  //         </div>
  //         <div className="w-full">
  //           <div className="h-1 w-full bg-gray-300 rounded-full overflow-hidden">
  //             <div style={{ width: "100%" }} className="bg-sky-500 opacity-90 h-full"></div>
  //           </div>
  //         </div>
  //       </div>
  //       <div className="w-full mt-4">
  //         <Tooltip placement="right" title={t("notAllowed")}>
  //           <div
  //             type="link"
  //             // disabled
  //             className="cursor-not-allowed flex items-center justify-center w-full h-7 text-white border-none bg-gray-800 rounded opactiy-90 hover:brightness-125 transition text-xs font-semibold"
  //             // onClick={showModal}
  //           >
  //             {t("subscription.button")}
  //           </div>
  //         </Tooltip>
  //       </div>
  //     </div>
  //   </div>
  // );

  const MenuButton = ({ icon, title, path, disabled = false }) => {
    const location = useLocation(); // Hook to get the current path
    const currentPath = location.pathname;

    const buttonStyle = disabled
      ? { height: "48px", backgroundColor: "transparent", color: "#a1a1a1", cursor: "not-allowed" } // bg-gray-50 equivalent in hex
      : { height: "48px" };

    const buttonContent = (
      <button
        type="text"
        className={`${
          currentPath.includes(path)
            ? "bg-white bg-opacity-30 text-white hover:bg-opacity-40 shadow-lg"
            : "text-gray-300 bg-opacity-30 bg-transparent hover:brightness-125"
        } w-full flex items-center space-x-4 px-4 text-sm border-none transition`}
        disabled={disabled}
        style={buttonStyle}
      >
        <span className={`${currentPath.includes(path) ? "text-white font-semibold" : "text-gray-500"}`}>{icon}</span>
        <span>{title}</span>
      </button>
    );

    return (
      <Link to={disabled ? "#" : path} style={{ width: "100%" }}>
        {disabled ? (
          <Tooltip placement="right" title={"Coming soon"}>
            <div>{buttonContent}</div>
          </Tooltip>
        ) : (
          buttonContent
        )}
      </Link>
    );
  };

  return (
    <>
      <SubscriptionModal open={open} setOpen={setOpen} />
      <div className="hidden lg:flex w-48 flex-col justify-between bg-gray-800 flex-shrink-0">
        <div className="">
          <Link to="/" className="py-3">
            <button className="flex items-center mx-3 my-4">
              <img
                src={"https://traddocs.s3.ap-northeast-2.amazonaws.com/landing/logo_name_red.png"}
                alt=""
                className="h-4 md:h-4 object-contain"
                draggable={false}
                style={{ filter: "brightness(0) invert(1)" }}
              />
            </button>
          </Link>
          <div className="w-full space-y-4 pb-4">
            <MenuButton icon={<MdDashboard />} title={"Dashboard"} path="/dashboard" />
            <MenuButton icon={<BsFileEarmarkSpreadsheetFill />} title={"Spreadsheet"} path="/spreadsheet" />
            <MenuButton icon={<IoPeopleSharp />} title={"Team"} path="/team" />
            {/* <MenuButton disabled={true} icon={<FaCode />} title={"API keys"} path="/api" /> */}
          </div>
          {/* <div className="w-full space-y-4 py-4">
            <MenuButton disabled={true} icon={<IoMdSettings />} title={t("menu3")} path="/settings" />
          </div> */}
        </div>
        <div className="pb-2">
          {/* <ClientHelp /> */}

          <div className="text-sm text-gray-300">
            <ProfileButton mode="dark" />

            <p className="text-gray-400 mt-2 text-xs px-2">
              © {new Date().getFullYear()} TradDocs. v {APP_VERSION}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeftPanel;
