import React, { useCallback, useEffect, useState } from "react";
import { List } from "react-virtualized";
import { IoIosWarning } from "react-icons/io";
import { Tooltip } from "antd";
import { LuLoader2 } from "react-icons/lu";
import { Document, Page } from "react-pdf";
import PDFPreviewControls from "./PDFPreviewControls";

const CrossCheckPdf = ({
  projectId,
  searchParams,
  pdfRefCompare,
  comparedData,
  contractData,
  comparedInvoiceData,
  comparedLcData,
  comparedDiData,
  splitterData,
  highlightedIndicies,
  extensionType,
  setHighlightedIndicies,
}) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [pageDimensions, setPageDimensions] = useState({ width: null, height: null });
  const [fileUrl, setFileUrl] = useState(null);
  const [constructedKeyBol, setConstructedKeyBol] = useState(null);
  const [constructedKeyContract, setConstructedKeyContract] = useState(null);
  const [constructedKeyInvoice, setConstructedKeyInvoice] = useState(null);
  const [constructedKeyLc, setConstructedKeyLc] = useState(null);
  const [constructedKeyDi, setConstructedKeyDi] = useState(null);

  const documentIdPath = searchParams.get("document_id");
  const splitIdPath = parseInt(searchParams.get("split_id"));
  const documentTypePath = searchParams.get("doc_type");

  const pageNumber = splitterData[documentIdPath]?.[splitIdPath]?.traddocs_watermark_indices?.[currentPage];

  const [zoomLevelCompare, setZoomLevelCompare] = useState(1);
  const [rotationAngle, setRotationAngle] = useState(0);

  useEffect(() => {
    if (!documentIdPath || !documentTypePath || !comparedData) return;

    const findKeyInData = (data, setData) => {
      let keyFound = null;
      for (const key in data) {
        if (key.startsWith(`${documentIdPath}_`)) {
          keyFound = key;
          break;
        }
      }
      setData(keyFound);
    };

    findKeyInData(comparedData, setConstructedKeyBol);
    findKeyInData(contractData, setConstructedKeyContract);
    findKeyInData(comparedInvoiceData, setConstructedKeyInvoice);
    findKeyInData(comparedLcData, setConstructedKeyLc);
    findKeyInData(comparedDiData, setConstructedKeyDi);
  }, [
    documentIdPath,
    splitIdPath,
    documentTypePath,
    comparedData,
    comparedInvoiceData,
    comparedLcData,
    contractData,
    comparedDiData,
  ]);

  useEffect(() => {
    if (documentIdPath) {
      setFileUrl(
        `https://traddocs.s3.ap-northeast-2.amazonaws.com/documents/${projectId}/source/${documentIdPath}.${extensionType}`
      );
    }
    return () => {};
  }, [documentIdPath, splitIdPath, documentTypePath, projectId, extensionType]);

  useEffect(() => {
    setCurrentPage(0);
    setRotationAngle(0);
    if (pdfRefCompare.current && pageDimensions.height) {
      const parentHeight = pdfRefCompare.current.clientHeight - 30;
      const newScale = parentHeight / pageDimensions.height;
      setZoomLevelCompare(newScale);
    }
  }, [pageDimensions, documentIdPath, splitIdPath, projectId, pdfRefCompare]);

  const onPageLoadSuccess = (page) => {
    const { width, height } = page.getViewport({ scale: 1 });
    setPageDimensions((prevDimensions) => {
      // Only update if dimensions have changed to avoid unnecessary re-renders
      if (prevDimensions.width !== width || prevDimensions.height !== height) {
        return { width, height };
      }
      return prevDimensions;
    });
  };

  const renderRow = useCallback(
    ({ index, key, style }) => {
      const renderCombinedRectangle = (
        polygons,
        pageWidthInInches,
        pageHeightInInches,
        id,
        isDiscrepancy,
        item,
        index
      ) => {
        if (!polygons || polygons.length === 0) return null;

        let minX = Infinity,
          minY = Infinity,
          maxX = -Infinity,
          maxY = -Infinity;

        polygons.forEach((polygon) => {
          const [x1, y1, x2, y2, x3, y3, x4, y4] = polygon.map((coord, i) =>
            i % 2 === 0
              ? (coord * pageDimensions.width) / pageWidthInInches
              : (coord * pageDimensions.height) / pageHeightInInches
          );

          minX = Math.min(minX, x1, x2, x3, x4);
          minY = Math.min(minY, y1, y2, y3, y4);
          maxX = Math.max(maxX, x1, x2, x3, x4);
          maxY = Math.max(maxY, y1, y2, y3, y4);
        });

        const left = minX * zoomLevelCompare;
        const top = minY * zoomLevelCompare;
        const width = (maxX - minX) * zoomLevelCompare;
        const height = (maxY - minY) * zoomLevelCompare;

        const isHighlighted = highlightedIndicies?.includes(id);

        function renderValue(value) {
          if (Array.isArray(value)) {
            return value.join(", "); // or return value.map((item, index) => <li key={index}>{item}</li>);
          } else if (typeof value === "object" && value !== null) {
            return JSON.stringify(value, null, 2);
          } else if (typeof value === "string") {
            return value;
          } else {
            return "N/A";
          }
        }

        const tooltipContent = (
          <div className="bg-white text-black px-3 py-3">
            {item.item && (
              <div className="font-semibold text-sm text-yellow-600 flex items-center space-x-2">
                <IoIosWarning className="text-yellow-500 w-5 h-5" />
                <p>{item.item}</p>
              </div>
            )}
            {item.discrepancy_details && (
              <div className="rounded-lg text-gray-500 mt-2 text-xs">{renderValue(item.discrepancy_details)}</div>
            )}
          </div>
        );

        const tooltipContent2 = (
          <div className="bg-white text-black px-3 py-3">
            {item.item && (
              <div className="font-semibold text-sm text-green-500 flex items-center space-x-2">
                <p>{item.item}</p>
              </div>
            )}
            {item.discrepancy_details && (
              <div className="rounded-lg text-gray-500 mt-2 text-xs">{renderValue(item.discrepancy_details)}</div>
            )}
          </div>
        );

        return (
          <Tooltip
            color={isDiscrepancy ? "#eab308" : "rgb(34 197 94)"}
            key={id}
            title={isDiscrepancy ? tooltipContent : tooltipContent2}
            overlayStyle={{ maxWidth: "400px" }}
            open={isHighlighted && true}
          >
            <div
              className="transition"
              key={id}
              style={{
                position: "absolute",
                left: `${left}px`,
                top: `${top}px`,
                width: `${width}px`,
                height: `${height}px`,
                borderWidth: isHighlighted && 2,
                borderColor: isDiscrepancy ? "red" : "rgb(34 197 94)",
                backgroundColor: isHighlighted ? "#eab30850" : "transparent",
                zIndex: isHighlighted ? 999 : 1,
              }}
            ></div>
          </Tooltip>
        );
      };

      const renderPageRectangles = (pageNumber, type, comparedData, constructedKey) => {
        const rectangles = [];
        if (type === "compared" && comparedData[constructedKey]) {
          if (comparedData[constructedKey]) {
            // Handle compared_a
            if (comparedData[constructedKey][splitIdPath].compared_a) {
              comparedData[constructedKey][splitIdPath].compared_a.forEach((item, index) => {
                if (item && item.coordinates) {
                  item.coordinates.forEach((coord) => {
                    if (coord && coord.page === pageNumber) {
                      rectangles.push(
                        renderCombinedRectangle(
                          coord.polygons,
                          coord.width,
                          coord.height,
                          item.id,
                          item.discrepancy,
                          item,
                          index
                        )
                      );
                    }
                  });
                }
              });
            }
            // Handle compared_b
            if (comparedData[constructedKey][splitIdPath].compared_b) {
              comparedData[constructedKey][splitIdPath].compared_b.forEach((itemArray) => {
                if (itemArray) {
                  itemArray.forEach((item, index) => {
                    if (item.coordinates) {
                      const coord = item.coordinates[1];
                      if (coord && coord.page === pageNumber) {
                        rectangles.push(
                          renderCombinedRectangle(
                            coord.polygons,
                            coord.width,
                            coord.height,
                            item.id,
                            item.discrepancy,
                            item,
                            index
                          )
                        );
                      }
                    }
                  });
                }
              });
            }
          }
        }

        if (
          (type === "comparedInvoice" || type === "contract" || type === "lc" || type === "di") &&
          comparedData[constructedKey]
        ) {
          if (comparedData[constructedKey]) {
            // Handle compared
            if (comparedData[constructedKey][splitIdPath].compared_b) {
              comparedData[constructedKey][splitIdPath].compared_b.forEach((itemArray) => {
                if (itemArray) {
                  itemArray.forEach((item, index) => {
                    if (item.coordinates) {
                      const coord = item.coordinates[1];
                      if (coord && coord.page === pageNumber) {
                        rectangles.push(
                          renderCombinedRectangle(
                            coord.polygons,
                            coord.width,
                            coord.height,
                            item.id,
                            item.discrepancy,
                            item,
                            index
                          )
                        );
                      }
                    }
                  });
                }
              });
            }
          }
        }

        return rectangles;
      };

      return (
        <div key={key} style={style}>
          <div className="pdf-page-compare">
            <Page
              // onLoadSuccess={onPageLoadSuccess}
              renderAnnotationLayer={false}
              renderTextLayer={false}
              pageNumber={pageNumber}
              scale={zoomLevelCompare}
              className={"relative shadow border"}
              loading={<LuLoader2 className="animate-spin w-10 h-10 text-white" />}
            >
              {comparedData?.[constructedKeyBol]?.[splitIdPath]?.compared_b[splitIdPath]?.length > 0 &&
                renderPageRectangles(pageNumber, "compared", comparedData, constructedKeyBol)}
              {comparedLcData?.[constructedKeyLc]?.[splitIdPath]?.compared_b[splitIdPath]?.length > 0 &&
                renderPageRectangles(pageNumber, "lc", comparedLcData, constructedKeyLc)}
              {comparedDiData?.[constructedKeyDi]?.[splitIdPath]?.compared_b[splitIdPath]?.length > 0 &&
                renderPageRectangles(pageNumber, "di", comparedDiData, constructedKeyDi)}
              {comparedInvoiceData?.[constructedKeyInvoice]?.[splitIdPath]?.compared_b[splitIdPath]?.length > 0 &&
                renderPageRectangles(pageNumber, "comparedInvoice", comparedInvoiceData, constructedKeyInvoice)}
              {contractData?.[constructedKeyContract]?.[splitIdPath]?.compared_b[splitIdPath]?.length > 0 &&
                renderPageRectangles(pageNumber, "contract", contractData, constructedKeyContract)}
            </Page>
          </div>
        </div>
      );
    },
    [
      comparedData,
      comparedInvoiceData,
      comparedLcData,
      comparedDiData,
      constructedKeyBol,
      constructedKeyContract,
      constructedKeyInvoice,
      constructedKeyLc,
      constructedKeyDi,
      contractData,
      highlightedIndicies,
      pageDimensions.height,
      pageDimensions.width,
      pageNumber,
      splitIdPath,
      zoomLevelCompare,
    ]
  );

  return (
    <div
      ref={pdfRefCompare}
      style={{ height: "100svh" }}
      onClick={() => setHighlightedIndicies([])}
      className="flex flex-col items-center overflow-auto w-full h-full relative cursor-pointer"
    >
      <div
        style={{
          transform: `rotate(${rotationAngle}deg)`,
          transformOrigin: "center",
        }}
        className="m-auto"
        onClick={(e) => e.stopPropagation()}
      >
        {fileUrl && (
          <Document
            file={fileUrl}
            onLoadError={(error) => console.error("Failed to load PDF file:", error)}
            loading={<LuLoader2 className="animate-spin w-10 h-10 text-white" />}
          >
            <Page
              className="hidden"
              onLoadSuccess={onPageLoadSuccess}
              pageNumber={splitterData[documentIdPath]?.[splitIdPath]?.traddocs_watermark_indices[0]}
              renderAnnotationLayer={false}
              renderTextLayer={false}
            ></Page>
            {pageDimensions.height && zoomLevelCompare && (
              <List
                width={pageDimensions.width * zoomLevelCompare || 800}
                height={pdfRefCompare.current?.clientHeight - 10 || 600}
                rowCount={1}
                rowHeight={pageDimensions.height * zoomLevelCompare}
                rowRenderer={renderRow}
              />
            )}
          </Document>
        )}
      </div>

      <div
        onClick={(e) => e.stopPropagation()}
        className="flex justify-center fixed bottom-2 bg-black bg-opacity-50 rounded-lg"
      >
        <PDFPreviewControls
          zoomLevel={zoomLevelCompare}
          setZoomLevel={setZoomLevelCompare}
          rotationAngle={rotationAngle}
          setRotationAngle={setRotationAngle}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          numPages={splitterData?.[documentIdPath]?.[splitIdPath]?.traddocs_watermark_indices.length}
        />
      </div>
    </div>
  );
};

export default CrossCheckPdf;
